import { UpdateManageDayRequestInterface } from "../contracts/ManageDayRequest"

export class UpdateManageDayApiRequest implements UpdateManageDayRequestInterface {
  public month?: number
  public year?: number
  public total_minimal_day?: number

  constructor(
    month?: number,
    year?: number,
    total_minimal_day?: number,
  ) {
    this.month = month,
    this.year = year,
    this.total_minimal_day = total_minimal_day
  }

  public toPayload(): string {
    const data = {
      month: this.month,
      year: this.year,
      total_minimal_day: this.total_minimal_day
    }

    return JSON.stringify({
      ...data,
    })
  }
}
