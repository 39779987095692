export enum EnumBonusStatus {
  APPROVE = 'APPROVE',
  APPROVE_WITH_ADJUSTMENT = 'APPROVE WITH ADJUSTMENT',
  WAITING_FOR_APPROVAL = 'WAITING FOR APPROVAL',
  REJECT = 'REJECT'
}

export enum EnumModalMessage {
  APPROVE = 'Bonus berhasil disetujui',
  APPROVE_WITH_ADJUSTMENT = 'Bonus berhasil disetujui dengan adjustment',
  REJECT = 'Bonus berhasil ditolak'
}
