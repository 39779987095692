import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { Utils } from '@/app/infrastructures/misc'
import { ManageDayPresenter } from '@/app/ui/presenters/ManageDayPresenter'
import { ManageDay, ManageDays, ManageDayYear, ManageDayYearList } from '@/domain/entities/ManageDay'
import { UpdateManageDayApiRequest } from '@/data/payload/api/ManageDayRequest'

export interface ManageDayState {
  isLoading: boolean
  dataManageDayList: ManageDay[]
  dataManageDay: ManageDay
  dataManageDayYearList: ManageDayYear[]
  isEditManageDaySuccess: boolean
}

export interface ManageDayEditPayload {
  id:  number
  month: number
  year: number
  total_minimal_day: number
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'manage-day',
})

class ManualDayController extends VuexModule implements ManageDayState {
  private presenter: ManageDayPresenter = container.resolve(ManageDayPresenter)
  public isLoading = false
  public dataManageDayList = [new ManageDay()]
  public dataManageDay = new ManageDay()
  public dataManageDayYearList = [new ManageDayYear()]
  public isEditManageDaySuccess = false

  @Action({ rawError: true })
  public getAll(params: Record<string, string | number>) {
    this.setLoading(true)
    this.clearDataManageDayList()

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        if (res.data) this.setDataManageDayList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Day List Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getYearList() {
    this.setLoading(true)

    this.presenter
      .getYearList()
      .then(res => {
        if (res.data) this.setDataManageDayYearList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Day Year List Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public update(payload: ManageDayEditPayload) {
    this.setLoading(true)
    this.presenter
      .update(payload.id, new UpdateManageDayApiRequest(
        payload.month,
        payload.year,
        payload.total_minimal_day
      ))
      .then(res => {
        this.setIsEditManageDaySuccess(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Manage Day Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsEditManageDaySuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setDataManageDayList(list: ManageDays) {
    this.dataManageDayList = <ManageDay[]>list.data
  }

  @Mutation
  private clearDataManageDayList() {
    this.dataManageDayList = []
  }

  @Mutation
  private setDataManageDayYearList(list: ManageDayYearList) {
    this.dataManageDayYearList = <ManageDayYear[]>list.data
  }

  @Mutation
  public setIsEditManageDaySuccess(bool: boolean) {
    this.isEditManageDaySuccess = bool
  }
}

export default getModule(ManualDayController)
