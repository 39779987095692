import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from "vuex-module-decorators";
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import store from '@/app/ui/store';
import { PayrollBonusPresenter } from "../presenters/PayrollBonusPresenter";
import { container } from "tsyringe";
import Vue from 'vue';
import { Pagination } from "@/domain/entities/Pagination";
import { Bonus, BonusPayroll, BonusPayrolls } from "@/domain/entities/PayrollBonus";
import { UpdateBonusPayrollApiRequest } from "@/data/payload/api/PayrollBonusRequest";

interface PayrollBonusState {
  isLoading: boolean,
  isLoadingExport: boolean,
  isLoadingRecalculate: boolean,
  dataBonus: Bonus,
  dataBonusPayroll: BonusPayroll[],
  pagination: Pagination
  isEditBonusPayrollStatusSuccess: boolean
  isEditBonusPayrollBulkStatusSuccess: boolean
  isRecalculationBonusSuccess: boolean
}

interface Iparams {
  page: number,
  per_page: number,
  status: string | number,
  month: string | number,
  year: string | number,
  keyword: string | number,
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'bonus-payroll'
})

class PayrollBonusController extends VuexModule implements PayrollBonusState {
  private presenter: PayrollBonusPresenter = container.resolve(PayrollBonusPresenter)
  public isLoading = false
  public isLoadingExport = false
  public isLoadingRecalculate = false
  public dataBonus = new Bonus()
  public dataBonusPayroll = [new BonusPayroll]
  public pagination = new Pagination()
  public isEditBonusPayrollStatusSuccess = false
  public isEditBonusPayrollBulkStatusSuccess = false
  public isRecalculationBonusSuccess = false

  @Action({ rawError: true })
  public getBonusPayroll(params: Iparams) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
    .getBonus(formattedParams)
    .then((res) => {
      this.setDataBonusPayroll(res)
    })
    .catch((error) => {
      Vue.notify({
        title: 'fetch data bonus payroll Failed',
        text:
          error.status === 400 || error.status === 422
          ? error.error.message.en
          : 'Something wrong',
        type: 'error',
        duration: 5000
      })
    })
    .finally(() => this.setLoading(false))
  }

  @Action({ rawError: true })
  public getDetail(id: string) {
    this.setLoading(true)

    this.presenter
      .get(id)
      .then(res => {
        if (res) {
          this.setDataBonus(res)
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Bonus Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportExcel(params: Record<string, string | number>) {
    this.setLoadingExport(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .export(formattedParams)
      .then((res) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8,' + encodeURIComponent(res);
        anchor.target = '_blank';
        anchor.download = 'Bonus.xlsx';
        anchor.click();
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Bonus Payroll Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingExport(false)
      })
  }

  @Action({ rawError: true })
  public update(payload: { ids: Array<number>, status: string }) {
    this.setLoading(true)
    this.presenter
      .update(new UpdateBonusPayrollApiRequest(
        payload.ids,
        payload.status,
      ))
      .then(res => {
        this.setEditBonusPayrollStatusSuccess(res)
        this.setEditBonusPayrollBulkStatusSuccess(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Bonus Status Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setEditBonusPayrollStatusSuccess(false)
        this.setEditBonusPayrollBulkStatusSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public recalculateBonus(params: Record<string, string | number>): void {
    this.setLoadingRecalculate(true)

    const formattedParams = Utils.toInstance(new Map(), JSON.stringify(params), 'snake_case')

    this.presenter
      .recalculateBonus(formattedParams)
      .then(() => {
        this.setIsRecalculationBonusSuccess(true)
        Vue.notify({
          title: 'Recalculate Bonus Success',
          text: 'Recalculate Bonus Success',
          type: 'success',
          duration: 3000,
        })
      })
      .catch((error) => {
        Vue.notify({
          title: 'Recalculate Bonus Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingRecalculate(false)
      })
  }

  @Mutation
  private setDataBonusPayroll(list: BonusPayrolls) {
    this.dataBonusPayroll = <BonusPayroll[]>list.data
    this.pagination = <Pagination>list.pagination
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingExport(bool: boolean) {
    this.isLoadingExport = bool
  }

  @Mutation
  private setLoadingRecalculate(bool: boolean): void {
    this.isLoadingRecalculate = bool
  }

  @Mutation
  private setDataBonus(list: Bonus) {
    this.dataBonus = list
  }

  @Mutation
  public setEditBonusPayrollStatusSuccess(bool: boolean) {
    this.isEditBonusPayrollStatusSuccess = bool
  }

  @Mutation
  public setEditBonusPayrollBulkStatusSuccess(bool: boolean) {
    this.isEditBonusPayrollBulkStatusSuccess = bool
  }

  @Mutation
  public setIsRecalculationBonusSuccess(bool: boolean): void {
    this.isRecalculationBonusSuccess = bool
  }
}

export default getModule(PayrollBonusController)
