
























































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import WarningIcon from '@/app/ui/assets/icon_warning_circle.vue'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import CheckBoxMinusIcon from '@/app/ui/assets/check_box_minus_2.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import CalendarIcon from '@/app/ui/assets/icon_calendar.vue'
import PopupBox from '../components/PopupBox/index.vue'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import controller from '@/app/ui/controllers/PayrollBonusController'
import controllerManageDay from '@/app/ui/controllers/ManageDayController'
import Loading from '@/app/ui/components/Loading/index.vue';
import { Utils } from '@/app/infrastructures/misc'
import { ManageDayYear } from '@/domain/entities/ManageDay'
import { EnumBonusStatus } from '@/app/infrastructures/misc/Constants/bonusPayroll'
import { Location } from 'vue-router'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

interface IStyle {
  minWidth: string
  maxWidth: string
}

interface ICell {
  title: boolean
  customStyle: IStyle
}

interface IDropdownValue {
  label: string | number,
  value: string | number
}

interface IParameters {
  page: number,
  per_page: number,
  keyword: string,
  status: IDropdownValue | string,
  month: IDropdownValue | string | number,
  year: IDropdownValue | string | number,
  sort: IDropdownValue | string,
}

export type TBonusPayrollList = [
  IBonusPayroll,
  string,
  number,
  number,
  string,
  string,
  string,
  string,
  number
][]

export interface IBonusPayroll {
  customStyle: CustomStyle
  title: boolean
}

export interface CustomStyle {
  maxWidth: string
  minWidth: string
}

@Component({
  components: {
    WarningIcon,
    Button,
    TextInput,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    ExpandIcon,
    CalendarIcon,
    PopupBox,
    Badge,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    CheckBoxMinusIcon,
    Loading,
    LoadingOverlay
  },
})

export default class BonusListPage extends Vue {
  enumBonusStatus = EnumBonusStatus
  controller = controller
  controllerManageDay = controllerManageDay
  popupFilterVisible = false
  calVisible = false
  mainCheckbox: 'all' | 'partial' | 'none' = 'none'
  dataBonusPayroll: Array<Array<string | number | {customStyle: IStyle, title: boolean} | undefined>> = []
  ids: Array<number> = []
  year = new Date().getFullYear()
  month = dayjs().month(new Date().getMonth() - 1).locale(dayjsID).format('MMMM')
  defaultFilter = {
    month: {
      label: this.month,
      value: this.month
    },
    year: {
      label: this.year,
      value: this.year
    }
  }

  sortOptions = [
    { label: 'Terbaru', value: 'DESC' },
    { label: 'Terlama', value: 'ASC' },
  ]

  statusOptions = [
    { label: 'Approve', value: 'approve' },
    { label: 'Approve With Adjustment', value: 'approve with adjustment' },
    { label: 'Waiting For Approval', value: 'waiting for approval' },
    { label: 'Reject', value: 'reject' },
  ]

  monthOptions: Array<{ label: string; value: string }> = []

  yearOptions: Array<{label: number, value: number}> = []

  parameters = {
    page: 1,
    per_page: 10,
    keyword: '',
    status: this.statusOptions[-1],
    month: {
      label: dayjs(this.month).format('MMMM'),
      value: dayjs(this.month).format('MMMM')
    },
    year: {
      label: this.year,
      value: this.year
    },
    sort: this.sortOptions[0],
  }

  headers = [
    {
      title: 'checkbox',
      customStyle: {
        minWidth: '48px',
        maxWidth: '48px',
      },
    },
    'Tgl. Bonus',
    'Bonus ID',
    'Driver ID',
    'Nama Driver',
    'No. Telepon',
    'Jumlah Bonus',
    'Status',
    'Action',
  ]

  perPageOptions: IDropdownValue[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ]

  created() {
    this.monthOptions = new Array(12).fill('').map((val, idx) => {
      return {
        label: dayjs()
          .month(idx)
          .locale(dayjsID)
          .format('MMMM'),
        value: dayjs()
          .month(idx)
          .locale(dayjsID)
          .format('MMMM'),
      }
    })

    if (dayjs().month() === 0) {
      this.parameters.month = this.monthOptions[11]
      this.parameters.year = {
        label: this.year - 1,
        value: this.year - 1
      }
    } else {
      this.parameters.month = this.monthOptions[dayjs().month() - 1]
    }

    this.fetchingDataBonusPayroll(this.parameters)
  }


  get bonusPayrollList(): Array<Array<string | number | {customStyle: IStyle, title: boolean} | undefined>> {
    return this.dataBonusPayroll
  }

  get indexingMonth(): string | number {
    if(this.monthOptions.indexOf(this.parameters.month) !== -1) {
      return this.monthOptions.indexOf(this.parameters.month) + 1
    }

    return new Date().getMonth()
  }

  get calValuePlaceholder(): string {
    if (this.parameters.month && this.parameters.year) {
      return `${this.parameters.month.value} ${this.parameters.year.value}`
    }
    return `${this.defaultFilter.month.value} ${this.defaultFilter.year.value}`
  }

  get isApproveDisable(): boolean {
    const itemState: Array<string> = this.getItemState(this.dataBonusPayroll)

    if (
      itemState.includes(EnumBonusStatus.APPROVE) ||
      itemState.includes(EnumBonusStatus.APPROVE_WITH_ADJUSTMENT) ||
      itemState.length === 0
    ) {
      return true
    }

    return false
  }

  get isDisableRecalculateButton(): boolean {
    const currentYear = new Date().getFullYear()
    const tempCurrentMonth = new Date(new Date().setDate(1))
    const currentMonthIdx = new Date(
      new Date(
        Utils.formatDateWithIDLocale(
          new Date().toISOString(), 'YYYY-MM-DD HH:mm') + '+07:00')).getMonth()
    const currentMonth = Utils.formatDateWithIDLocale(
      new Date(tempCurrentMonth.setMonth(currentMonthIdx - 1)).toISOString(),
      'MMMM')

    return (
      this.parameters.year.value != currentYear ||
      this.parameters.month.value != currentMonth 
    )
  }

  get isRejectDisable(): boolean {
    const itemState: Array<string> = this.getItemState(this.dataBonusPayroll)

    if (
      itemState.includes(EnumBonusStatus.APPROVE) ||
      itemState.includes(EnumBonusStatus.APPROVE_WITH_ADJUSTMENT) ||
      itemState.includes(EnumBonusStatus.REJECT) ||
      itemState.length === 0
    ) {
      return true
    }

    return false
  }

  private getItemState(data: Array<Array<string | number | {customStyle: IStyle, title: boolean} | undefined>>): Array<string> {
    const itemState: Array<string> = []

    data.forEach(item => {
      const isCheckItem = <ICell>item[0]
      const statusItem = <string>item[7]
      if (isCheckItem.title === true) {
        itemState.push(statusItem)
      }
    })

    return itemState
  }

  private onResetFilterBox(): void {
    this.popupFilterVisible = false
    this.parameters.status = this.statusOptions[-1]
    this.onResetCal()
  }

  private onResetCal(): void {
    this.calVisible = false
    this.popupFilterVisible = false

    if (dayjs().month() === 0) {
      this.parameters.month = this.monthOptions[11]
      this.parameters.year = {
        label: this.year - 1,
        value: this.year - 1
      }
    } else {
      this.parameters.month = this.monthOptions[dayjs().month() - 1]
    }

    this.fetchingDataBonusPayroll(this.parameters, true)
  }

  private onExportExcel(): void {
    const exportParams: Record<string, string | number> = {
      sort: this.parameters.sort.value,
      keyword: this.parameters.keyword,
      month: this.indexingMonth,
      year: this.parameters.year.value,
      status: this.parameters.status?.value.toUpperCase() || '',
    }

    controller.exportExcel(exportParams)
  }

  public onRecalculation(): void {
    const recalculateParams = {}

    this.controller.recalculateBonus(recalculateParams)
  }

  private onUpdateStatus(status: string): void {
    const payload = {
      ids: this.ids,
      status: status
    }

    controller.update(payload)
  }

  private setCalFilter():void {
    this.calVisible = false
    this.popupFilterVisible = false
    this.fetchingDataBonusPayroll(this.parameters, true)
  }

  private onClickMainCheckBox(): void {
    if (this.mainCheckbox === 'none') {
      this.dataBonusPayroll.forEach(item => {
        const isCheckItem = <ICell>item[0];
        const idItem = <number>item[8];
        isCheckItem.title = true
        item.splice(0, 1, isCheckItem)
        this.ids.push(idItem)
      })
    } else if (this.mainCheckbox === 'all' || this.mainCheckbox === 'partial') {
      this.dataBonusPayroll.forEach(item => {
        const isCheckItem = <ICell>item[0];
        isCheckItem.title = false
        item.splice(0, 1, isCheckItem)
      })
      this.ids = []
    }
  }

  private setItemCheckBox(index: number, indexField: number, value: boolean): void {
    const item = this.bonusPayrollList[index]
    const isCheckItem = <ICell>item[0];
    const idItem = <number>item[8];
    isCheckItem.title = !value
    item.splice(indexField, 1, isCheckItem)

    if (isCheckItem.title) {
      this.ids.push(idItem)
    } else {
      const idx = this.ids.indexOf(idItem)
      this.ids.splice(idx, 1)
    }
  }

  private setBadgeStyle(status: string): string {
    if (status.toUpperCase() === EnumBonusStatus.APPROVE) {
      return 'success'
    } else if (status.toUpperCase() === EnumBonusStatus.WAITING_FOR_APPROVAL) {
      return 'warning'
    } else if (status.toUpperCase() === EnumBonusStatus.REJECT) {
      return 'error'
    } else if (status.toUpperCase() == EnumBonusStatus.APPROVE_WITH_ADJUSTMENT) {
      return 'success'
    }
    return ''
  }

  private setBadgeText(status: string): string {
    return status
  }

  private routeToDetail(id: string): Location {
    return {
      name: 'BonusDetail',
      params: { id: id }
    }
  }

  private fetchingDataBonusPayroll(parameters: IParameters, reset?: boolean): void {
    if (reset) this.parameters.page = 1

    const params = {
      page: parameters.page,
      per_page: parameters.per_page,
      status: this.parameters.status?.value ? this.parameters.status?.value.toUpperCase() : '',
      month: this.indexingMonth,
      year: this.parameters.year.value,
      keyword: encodeURIComponent(parameters.keyword),
      sort: this.parameters.sort.value
    }

    controller.getBonusPayroll(params)
    controllerManageDay.getYearList()
  }

  private manageDataBonusPayrolls(): void {
    let bonusPayrolls: Array<Array<string | number | {customStyle: IStyle, title: boolean} | undefined>> = []
    if(this.controller.dataBonusPayroll.length > 0) {
      const dataPayrollForTable =  this.controller.dataBonusPayroll.map((data) => {
        return [
          {
            customStyle: {
              maxWidth: '48px',
              minWidth: '48px'
            },
            title: false
          },
          `${data.month ? dayjs().month(data.month - 1).locale(dayjsID).format('MMMM') : ''} ${data.year}`,
          data?.id,
          data.courier?.id,
          data.courier?.fullname,
          data.courier?.phoneNumber,
          `Rp${Utils.currencyDigit(Number(data.totalAmount))}`,
          data.status,
          data.id
        ]
      })

      bonusPayrolls = dataPayrollForTable
    }
    this.dataBonusPayroll = bonusPayrolls
  }

  private fetchingManualBonusPayrollList(parameters: IParameters): void {
    this.fetchingDataBonusPayroll(parameters)
  }

  private fetchingManualBonusPayrollListPerPage(value: number): void {
    this.parameters.per_page = value
    this.fetchingDataBonusPayroll(this.parameters, true)
  }

  private filterBonusList = Utils.debounce(() => {
    this.fetchingDataBonusPayroll(this.parameters, true)
  }, 400)

  @Watch('bonusPayrollList')
  setMainCheckboxItem(data: TBonusPayrollList): void {
    const itemState = data.map(item => {
      if (item[0]) {
        const payload = <ICell>item[0]
        return payload.title
      }
    })
    const checkboxSet = new Set(itemState)

    if (checkboxSet.size === 2) {
      this.mainCheckbox = 'partial'
    } else {
      const value = checkboxSet.values().next().value

      if (value === true) {
        this.mainCheckbox = 'all'
      } else if (value === false) {
        this.mainCheckbox = 'none'
      }
    }
  }

  @Watch('controller.dataBonusPayroll')
  setDataBonusPayroll(): void {
    this.manageDataBonusPayrolls()
  }

  @Watch('controllerManageDay.dataManageDayYearList')
  setDataYearList(list: ManageDayYear[]): void {
    const year: Array<{label: number, value: number}> = []
    list.forEach(item => {
      if(item.year) {
        year.push({
          label: item.year,
          value: item.year
        })
      }
    })
    this.yearOptions = year
  }

  @Watch('parameters.sort')
  setSortingData(): void {
    this.fetchingDataBonusPayroll(this.parameters, true)
  }

  @Watch('parameters.status')
  setStatusData(): void {
    this.fetchingDataBonusPayroll(this.parameters, true)
    this.popupFilterVisible = false
  }

  @Watch('controller.isEditBonusPayrollBulkStatusSuccess')
  setEditBonusPayrollBulkStatusSuccess(response: boolean): void {
    if (response) {
        this.$notify({
          title: 'Update Bonus Status Success',
          text: 'Bonus Status is Successfully Updated',
          type: 'success',
          duration: 5000,
        })
      }
    this.ids = []
    this.fetchingDataBonusPayroll(this.parameters)
  }

  @Watch('controller.isRecalculationBonusSuccess')
  onIsRecalculationBonusSuccess(data: boolean): void {
    if (data) {
      this.fetchingDataBonusPayroll(this.parameters)
      this.controller.setIsRecalculationBonusSuccess(false)
    }
  }

  beforeDestroy(): void {
    this.controller.setEditBonusPayrollBulkStatusSuccess(false)
  }
}
