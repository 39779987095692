import { UpdateBonusPayrollRequestInterface } from "../contracts/PayrollBonusRequest"

export class UpdateBonusPayrollApiRequest implements UpdateBonusPayrollRequestInterface {
  public ids?: Array<number>
  public status?: string

  constructor(
    ids?: Array<number>,
    status?: string,
  ) {
    this.ids = ids,
    this.status = status
  }

  public toPayload(): string {
    const data = {
      ids: this.ids,
      status: this.status,
    }

    return JSON.stringify(data)
  }
}
